


























































import Vue from 'vue'
import { cloneDeep } from 'lodash'
import { mapActions } from 'vuex'

export default Vue.extend({
  name: 'ArchivedOrders',

  props: {
    ordersItems: {
      type: Array,
      default: () => []
    },
    fetchParams: {
      type: Object,
      default: () => ({})
    }
  },

  data () {
    return {
      localOrders: [],
      fields: [
        {
          key: 'operator',
          label: 'Оператор',
          sortable: true
        },
        {
          key: '_courier',
          label: 'Курьер'
        },
        {
          key: 'number',
          label: '№ заказа'
        },
        {
          key: 'created_at',
          label: 'Дата и время заказа',
          sortable: true
        },
        {
          key: '_status',
          label: 'Статус заказа',
          sortable: true
        },
        {
          key: 'canceled_reason',
          label: 'Причина отмены'
        },
        {
          key: '_client_phone',
          label: 'Телефон клиента'
        },
        {
          key: 'business_profile_login',
          label: 'Логин БП',
          sortable: true
        },
        {
          key: 'business_profile_phone',
          label: 'Телефон бизнеса'
        },
        {
          key: '_products',
          label: 'Состав заказа'
        },
        {
          key: '_deliveryMethod',
          label: 'Способ получения'
        },
        {
          key: '_paymentMethod',
          label: 'Способ оплаты'
        },
        {
          key: 'business_location.address',
          label: 'Локация бизнеса'
        },
        {
          key: 'delivery.address',
          label: 'Адрес доставки'
        },
        {
          key: 'comment',
          label: 'Комментарий'
        },
        {
          key: 'promo_code.code',
          label: 'Промокод'
        },
        {
          key: 'order_price',
          label: 'Стоимость заказа'
        },
        {
          key: 'fact_price',
          label: 'Сумма в чеке'
        },
        {
          key: 'delivery_price',
          label: 'Стоимость доставки'
        },
        {
          key: 'package_price',
          label: 'Стоимость посуды'
        },
        {
          key: 'discount_amount',
          label: 'Скидка'
        },
        {
          key: 'total_price',
          label: 'Общая стоимость'
        },
        {
          key: 'order_time',
          label: 'Время обработки заказа'
        }
      ]
    }
  },

  watch: {
    ordersItems: {
      handler (v) {
        this.localOrders = cloneDeep(v)
      },
      immediate: true
    }
  },

  computed: {
    sortedBy () {
      const replacedValues = {
        operator: 'operator',
        created_at_timestamp: 'created_at',
        status_index: '_status',
        business_profile_id: 'business_profile_login'
      }
      if ((typeof this.fetchParams?.sort_type === 'boolean' && this.fetchParams.sort_type) || (typeof this.fetchParams?.sort_type === 'string' && this.fetchParams.sort_type === 'true')) {
        return replacedValues[this.fetchParams.sort_by]
      }
      return null
    }
  },

  methods: {
    ...mapActions({
      editOrderAdministration: 'orders/editOrderAdministration'
    }),
    async sortingChanged ({ sortBy, sortDesc }) {
      const replacedValues = {
        operator: 'operator',
        created_at: 'created_at_timestamp',
        _status: 'status_index',
        business_profile_login: 'business_profile_id'
      }
      const sortValue = replacedValues[sortBy]
      this.$emit('sorting-change', { sortBy: sortValue, sortDesc: !sortDesc })
    },

    onClickOpenBusinessProfile (id) {
      this.$router.push({ name: 'profile-detail', params: { profileId: id } })
    },

    onInputFactPrice (order) {
      if (order.fact_price === '') {
        order.fact_price = 0
        return
      }
      if (order.fact_price.includes('.')) {
        order.fact_price = Number(order.fact_price).toFixed(2)
      }
      this.editOrderAdministration({
        id: order.id,
        payload: {
          courier: order._courier,
          delivery: order.delivery,
          fact_price: order.fact_price
        }
      })
    }
  }
})
